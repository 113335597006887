'use client'

import {
	classNameFunc
} from '@dg/common/lib/common'
import {
	InputHTMLAttributes, ReactNode
} from 'react'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	borderType?: `none` | `outline` | `underline`;
	className?: string;
	customElement?: ReactNode;// checkbox, radio에서 사용
	defaultBgTransparent?: boolean;
	defaultSize?: boolean; // checkbox, radio에서 사용
	defaultTextSize?: boolean;
	error?: boolean;
	height?: string;
	hover?: boolean;
	labelClassName?: string; // checkbox, radio에서 사용
	labelVerticalAlign?: `center` | `end` | `start`; // checkbox, radio에서 사용
	pd?: boolean;
	px?: boolean;
	py?: boolean;
	rounded?: boolean;
	spanClassName?: string; // checkbox, radio에서 사용
	text?: string; // checkbox, radio에서 사용
	transition?: boolean;
	width?: string;
}

const Input = (props: InputProps) => {
	const {
		className = ``, text = ``, id, type, labelClassName = ``, spanClassName = ``, height = `h-12`, width = `w-auto`,
		borderType = `outline`, px = false, py = false, pd = false, rounded = false, hover = false,
		defaultSize = true, transition = true, defaultTextSize = true, labelVerticalAlign = `center`,
		defaultValue, defaultChecked, defaultBgTransparent = true, error = false, customElement,
		...rest
	} = props

	const inputChk = type === `radio` || type === `checkbox`

	const customClassName =
		classNameFunc(`
			cursor-pointer appearance-none overflow-hidden outline-none placeholder:text-dg-600
			disabled:cursor-not-allowed dark:placeholder:text-dgdark-600
			${defaultBgTransparent === true && error === false ? `bg-transparent` : ``}
			${transition === true ? `transition-colors` : ``}
			${
				type === `radio` || type === `checkbox` ?
					`bg-full` :
					`text-left disabled:bg-dg-300 dark:disabled:bg-dgdark-300`
			}
			${
				type === `radio` ?
					`
						bg-radio checked:bg-radioChecked disabled:bg-radioDisabled
						disabled:checked:bg-radioDisabledChecked
						dark:bg-radioDark dark:checked:bg-radioDarkChecked
						dark:disabled:bg-radioDarkDisabled
						dark:disabled:checked:bg-radioDarkDisabledChecked
					` :
					``
			}
			${
				type === `checkbox` ?
					`
						bg-checkbox checked:bg-checkboxChecked disabled:bg-checkboxDisabled
						disabled:checked:bg-checkboxDisabledChecked
						dark:bg-checkboxDark dark:checked:bg-checkboxDarkChecked
						dark:disabled:bg-checkboxDarkDisabled
						dark:disabled:checked:bg-checkboxDarkDisabledChecked
					` :
					``
			}
			${
				borderType !== `none` && type !== `radio` && type !== `checkbox` ?
					`
						border-solid
						${borderType === `outline` ? `border` : `border-b`}
						${
							error === false ?
								`
									border-dg-500 focus:border-dg-black
									dark:border-dgdark-500 dark:focus:border-dgdark-black
								` :
								`
									border-solid border-dg-red bg-dg-red100
									dark:border-dgdark-red dark:bg-dgdark-red100
								`
						}
					` :
					``
			}
			${defaultSize === true && type === `radio` ? `size-6` : ``}
			${defaultSize === true && type === `checkbox` ? `size-5` : ``}
			${pd === true && type !== `radio` && type !== `checkbox` && type !== `date` ? `p-4` : ``}
			${pd === true && type === `date` ? `py-4 pl-4 pr-10` : ``}
			${py === true && type !== `radio` && type !== `checkbox` ? `py-4` : ``}
			${px === true && type !== `radio` && type !== `checkbox` && type !== `date` ? `px-4` : ``}
			${px === true && type === `date` ? `pl-4 pr-10` : ``}
			${rounded === true && inputChk === true ? `rounded-full` : ``}
			${rounded === true && inputChk === false && borderType === `outline` ? `rounded-lg` : ``}
			${
				hover === true && error === false ?
					`
						hover:border-dg-black hover:bg-dg-500/30
						dark:hover:border-dgdark-black dark:hover:bg-dgdark-500/30
					` :
					``
			}
			${defaultTextSize === true ? `text-sm` : ``}
			${defaultSize === false && inputChk === true ? `${height} ${width}` : ``}
			${inputChk === false ? `relative text-dg-900 dark:text-dgdark-900 ${height} ${width}` : ``}
			${className}
		`)

	const customLabelSpanClassName =
		classNameFunc(`
			cursor-pointer text-dg-600 checked-next:text-dg-900
			disabled-next:text-dg-600 dark:text-dgdark-400
			dark:checked-next:text-dgdark-900 dark:disabled-next:text-dgdark-400
			${transition === true ? `transition-colors` : ``}
			${defaultTextSize === true ? `text-sm` : ``}
			${spanClassName}
		`)

	return (
		type !== `checkbox` && type !== `radio` ?
			(
				<input
					{...rest}
					key={`${id ?? ``}-${(defaultValue ?? ``).toString()}`}
					className={customClassName}
					defaultValue={defaultValue}
					id={id}
					type={type}
				/>
			) :
			(
				<label
					htmlFor={id}
					className={
						classNameFunc(`
							inline-flex space-x-1
							${transition === true ? `transition-colors` : ``}
							${labelVerticalAlign === `start` ? `items-start` : ``}
							${labelVerticalAlign === `center` ? `items-center` : ``}
							${labelVerticalAlign === `end` ? `items-end` : ``}
							${labelClassName}
						`)
					}
				>
					<input
						{...rest}
						key={`${id ?? ``}-${(defaultChecked ?? ``).toString()}`}
						className={customClassName}
						defaultChecked={defaultChecked}
						id={id}
						type={type}
					/>

					{
						text !== `` && (
							<span
								className={customLabelSpanClassName}
								dangerouslySetInnerHTML={
									{
										__html: text
									}
								}
							/>
						)
					}

					{
						customElement && (
							<span
								className={customLabelSpanClassName}
							>
								{customElement}
							</span>
						)
					}
				</label>
			)
	)
}

export default Input
